// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import Servicos from "./components/Servicos";
import Sobre from "./components/Sobre";
import Contato from "./components/Contato";

function Home() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
function Portfolioz() {
  return (
    <>
      <Header />
      <Portfolio />
      <Footer />
    </>
  );
}
function Servicoss() {
  return (
    <>
      <Header />
      <Servicos />
      <Footer />
    </>
  );
}
function Contat() {
  return (
    <>
      <Header />
      <Contato />
      <Footer />
    </>
  );
}
function Sobr() {
  return (
    <>
      <Header />
      <Sobre />
      <Footer />
    </>
  );
}
function App() {
  return (
      <Router>
    <div className='bg-black relative' id="hero-2">
    <div class="gradient-bg">
  <svg xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
  <div class="gradients-container">
    <div class="g1"></div>
    <div class="g2"></div>
    <div class="g3"></div>
    <div class="g4"></div>
    <div class="g5"></div>
    <div class="interactive"></div>
  </div>
</div>
      <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/portfolio' element={<Portfolioz/>} />
      <Route path='/servicos' element={<Servicoss/>} />
      <Route path='/sobre' element={<Sobr/>} />
      <Route path='/contato' element={<Contat/>} />
      </Routes>
    </div>
  </Router>
      
  );
}

export default App;