import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Importa o EmailJS
import '../index.css';

// import contact data
import { contact } from '../data';

const Contato = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState(''); // Para exibir o status

  // Função para lidar com as alterações nos inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Função para enviar o formulário
  const handleSubmit = (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    // Configurações do EmailJS
    emailjs
      .send(
        'seu_service_id', // Substitua pelo seu Service ID
        'seu_template_id', // Substitua pelo seu Template ID
        formData,
        'sua_public_key' // Substitua pela sua Public Key do EmailJS
      )
      .then(
        (response) => {
          setStatusMessage('Email enviado com sucesso!');
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          setStatusMessage('Ocorreu um erro ao enviar o email.');
          console.log('FAILED...', error);
        }
      );
  };

  return (
    <div>
      <div className="contato">
        <center>
          <div className="container-max">
            <section id="contato" className="section bg-primary min-h-[1400px]">
              <div className="hero-container">
                <div className="flex flex-col items-center text-center">
                  <h2 className="section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block">
                    Contato
                  </h2>
                  <p className="subtitle">
                    Entre em contato comigo, caso tenha alguma dúvida.
                  </p>
                </div>

                <div className="flex flex-col lg:gap-x-8 lg:flex-row">
                  <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
                  <h2 className="section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block">
                      Informações
                    </h2>
                    {contact.map((item, index) => {
                      const { icon, title, subtitle, description } = item;
                      return (
                        <div className="flex lg:flex-row gap-x-4" key={index}>
                          <div className="text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                            {icon}
                          </div>
                          <div>
                            <h4 className="font-body text-xl mb-1">{title}</h4>
                            <p className="mb-1 text-paragraph">{subtitle}</p>
                            <p className="text-accent font-normal ">{description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <form
                    className="space-y-8 w-full max-w-[780px]"
                    onSubmit={handleSubmit} // Adiciona o evento onSubmit
                  >
                    <div className="flex gap-8">
                      <input
                        className="input"
                        type="text"
                        name="name"
                        placeholder="Nome"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <input
                      className="input"
                      type="text"
                      name="subject"
                      placeholder="Assunto"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                    <textarea
                      className="textarea"
                      name="message"
                      placeholder="Mensagem"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <button
                      className="btn btn-lg bg-accent hover:bg-secondary-hover"
                      type="submit"
                    >
                      Enviar
                    </button>
                  </form>
                </div>
                {/* Exibe mensagem de status */}
                {statusMessage && (
                  <p className="text-center mt-4 text-white">{statusMessage}</p>
                )}
              </div>
            </section>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Contato;
