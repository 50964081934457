import React from 'react';
import '../index.css';
import Anime from 'react-anime';
import { Link } from 'react-router-dom';

import Image from '../assets/img/eu.jpg';

const flipInX = {
  opacity: [0, 1],
  translateX: [-100, 0],
  rotateX: [-90, 0],
  easing: 'easeOutCubic',
  duration: 1000,
  separator: ",",
  speed: 3000
};

function Main() {
  return (
    <div>
      {/*==========================
        Hero Section
      ============================*/}
      <section id="hero">
        <div className="hero-container">
          <div className="wow fadeIn">
            <div className="hero-logo"><center><img alt="Imperial" width="200px" height="200px" src={Image} style={{borderRadius: '100px'}} /></center></div>

            <h1>Olá, me chamo <strong className='strong-name'> Giovanni</strong></h1>

            <Anime {...flipInX}><h2>Trabalho com <span className="rotating">PHP, Nodejs e Wordpress!</span></h2></Anime>

            <div className="actions"><Link className="btn-get-started" to="/portfolio" target="" rel="noreferrer">PORTFÓLIO E DEMOS</Link> <Link className="btn-services" to="/contato" target="" rel="noreferrer">CONTATO</Link><Link className="btn-services" to="/sobre" rel="noreferrer">SOBRE</Link></div>
          </div>
        </div>
      </section>
      {/*==========================
        Header Section
      ============================*/}
      {/*==========================
        Footer
      ============================*/}
      {/* #footer */}
    </div>
  );
}

export default Main;